import { useIsSmallSearchPopupOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import Popup from '@/components/shared/Popup';
import { m } from 'framer-motion';
import Button from '@/components/shared/Button';
import SearchInput from '@/components/shared/SearchInput';
import { useFiltersVisibleState } from '@/atoms/search-popup-visible';
import { tp } from '@/typograf';

export const SMALL_SEARCH_POPUP_NAME = 'small-search';

interface Props {}

const SmallSearchPopup = ({}: Props) => {
    const isOpened = useIsSmallSearchPopupOpenedValue();
    const { closePopup } = useSetOpenedPopupsState();
    const setFiltersVisible = useFiltersVisibleState();

    return (
        <Popup
            name={SMALL_SEARCH_POPUP_NAME}
            className="small-search-popup"
            overlay
            onClose={() => setFiltersVisible(true)}
        >
            <m.div
                variants={{
                    visible: {
                        transition: {
                            duration: 0.5,
                        },
                        opacity: 1,
                        y: 0,
                    },
                    hidden: {
                        opacity: 0,
                        y: 20,
                    },
                }}
                className="small-search-popup__inner"
                initial="hidden"
                animate={isOpened ? 'visible' : 'hidden'}
            >
                <div className="small-search-popup__main">
                    <div className="catalog-popup-header">
                        <div className="catalog-popup-title text-xs">поиск товара</div>
                        <Button
                            geometryVariant="square-bracket"
                            onClick={() => {
                                closePopup(SMALL_SEARCH_POPUP_NAME);
                            }}
                            aria-label="Закрыть сортировку"
                            className="catalog-popup-close close-button"
                        >
                            X
                        </Button>
                    </div>

                    <div className="small-search-popup__body">
                        <div className="catalog-search catalog-search-in-catalog">
                            <SearchInput name="q" label={tp('Введите бренд, артикул, модель ...')} isBigSubmitBtn />
                        </div>
                    </div>
                </div>
            </m.div>
        </Popup>
    );
};

export default SmallSearchPopup;

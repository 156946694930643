import Popup from '@/components/shared/Popup';
import { m } from 'framer-motion';
import { useRef, useState, TouchEvent, MouseEvent, useEffect } from 'react';
import { useIsProductVideoPopupOpenedValue } from '@/atoms/opened-popups';
import { VideoShape } from '@/types';
import Button from '@/components/shared/Button';
import PlaySVG from '@/svg/play.svg';
import PauseSVG from '@/svg/pause.svg';
import OnSoundSVG from '@/svg/on-sound.svg';
import OffSoundSVG from '@/svg/off-sound.svg';

interface Props {
    video: VideoShape;
}

export const PRODUCT_VIDEO_POPUP_NAME = 'product-video';

const ProductVideoPopup = ({ video }: Props) => {
    const isOpened = useIsProductVideoPopupOpenedValue();
    const videoRef = useRef<HTMLVideoElement>(null);
    const controlsElRef = useRef<HTMLDivElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(true);
    const [isDragging, setIsDragging] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(100);

    useEffect(() => {
        const videoCurrent = videoRef.current;
        if (videoCurrent) {
            isOpened ? setIsPlaying(true) : setIsPlaying(false);
        }
    }, [isOpened]);

    useEffect(() => {
        const videoCurrent = videoRef.current;
        if (videoCurrent) {
            isPlaying ? videoCurrent.play() : videoCurrent.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        const videoCurrent = videoRef.current;
        if (videoCurrent) {
            const updateProgress = () => {
                setCurrentTime(videoCurrent.currentTime);
                setDuration(videoCurrent.duration);
            };

            videoCurrent.addEventListener('timeupdate', updateProgress);
            return () => {
                videoCurrent.removeEventListener('timeupdate', updateProgress);
            };
        }
    }, []);

    function onTimelineBarStart(event: TouchEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        setIsDragging(true);
        const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX;
        updateVideoTime(clientX);
    }

    function onTimelineBarMove(event: TouchEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>) {
        if (isDragging) {
            const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX;
            updateVideoTime(clientX);
        }
    }

    function onTimelineBarEnd() {
        setIsDragging(false);
    }

    function updateVideoTime(clientX: number) {
        const videoCurrent = videoRef.current;
        const target = controlsElRef.current;
        if (!target || !duration) return;

        const rect = target.getBoundingClientRect();
        const fraction = Math.min(Math.max((clientX - rect.left) / rect.width, 0), 1);
        const seconds = Math.max(0.01, duration * fraction);

        if (videoCurrent) {
            videoCurrent.currentTime = seconds;
            setCurrentTime(seconds);
        }
    }

    return (
        <Popup name={PRODUCT_VIDEO_POPUP_NAME} className="product-video-popup" overlay>
            <m.div
                className="product-video-popup__inner"
                variants={{
                    visible: {
                        transition: {
                            duration: 0.8,
                        },
                        opacity: 1,
                        scale: 1,
                    },
                    hidden: {
                        opacity: 0,
                        scale: 0.8,
                    },
                }}
                initial="hidden"
                animate={isOpened ? 'visible' : 'hidden'}
            >
                <>
                    <video playsInline loop className="product-slide-main-image" muted={isMuted} ref={videoRef}>
                        {video.map((item, i) => {
                            return (
                                <source
                                    key={i}
                                    type={item.type === 'video/quicktime' ? 'video/mp4' : item.type}
                                    src={item.src}
                                />
                            );
                        })}
                    </video>
                    <div ref={controlsElRef} className="video-player-controls">
                        <button
                            className="video-player-controls__timeline"
                            onTouchStart={onTimelineBarStart}
                            onTouchMove={onTimelineBarMove}
                            onTouchEnd={onTimelineBarEnd}
                            onMouseDown={onTimelineBarStart}
                            onMouseMove={onTimelineBarMove}
                            onMouseUp={onTimelineBarEnd}
                            aria-label="Быстро перейти на фрагмент"
                        >
                            <span
                                className="video-player-controls__timeline-el"
                                style={{
                                    transform: `scaleX(${currentTime / duration})`,
                                }}
                            ></span>
                            {duration && (
                                <span
                                    className="video-player-controls__timeline-circle"
                                    style={{
                                        transform: `translate(${
                                            (currentTime / duration) * (controlsElRef.current?.clientWidth ?? 0)
                                        }px, 0)`,
                                    }}
                                ></span>
                            )}
                        </button>
                    </div>
                    <div className="product-video__btns-wrapper">
                        <Button
                            size="sm"
                            geometryVariant="mustache"
                            icon={isPlaying ? <PauseSVG /> : <PlaySVG />}
                            className="review-card__control product-video-btn"
                            onClick={() => {
                                setIsPlaying((prev) => !prev);
                            }}
                        />
                        <Button
                            size="sm"
                            geometryVariant="mustache"
                            icon={isMuted ? <OffSoundSVG /> : <OnSoundSVG />}
                            className="video-popup-sound"
                            onClick={() => {
                                setIsMuted((prev) => !prev);
                            }}
                        />
                    </div>
                </>
            </m.div>
        </Popup>
    );
};

export default ProductVideoPopup;
